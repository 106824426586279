import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomPaginationExpenseGrid from "../CustomPagination//CustomPaginationExpenseGrid";
import FilterIcon from "@mui/icons-material/FilterList";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import "./style.scss";

const getRowIdInquiry = (row) => {
  const possibleFields = [ "id", "Id", "customerID", "customerId", "jobCode" ];

  let idStr = '';
  for (let field of possibleFields) {
    if (row[field]) {
      idStr = idStr + `-${row[field]}`;
    }
  }

  if (idStr === '') {
    throw new Error('No id fields present for DataGrid row')
  }

  idStr = idStr.slice(1)
  idStr = idStr.toLowerCase();

  return idStr;
}

export default function DataTable({ rows, columns, ...props }) {
  // eslint-disable-next-line no-unused-vars
  const handleFilter = (event, column) => {};
  return (
    <div className="custom-data-grid">
      <DataGrid 
      sx={ {'--unstable_DataGrid-radius':'0px !important',}}
      {...props}
        data-testid="data-table"
        checkboxSelection={false}
        rows={rows}
        getRowId={getRowIdInquiry}
        columns={columns.map((col) => ({
          ...col,
          headerClassName: "custom-header",
          renderHeader: (params) => (
            <div className="header-wrapper" style={{ display: "flex", width: "100%" }}>
              <div className="heading">
                {params.colDef.isCalcutateIcon && <CalculateOutlinedIcon />}
                {params.colDef.headerName}
              </div>

              <FilterIcon className="filter-icon" onClick={(event) => handleFilter(event, params.colDef)} />
            </div>
          ),
        }))}
        pagination={true}
        slots={{
          pagination: rows.length > 0 && CustomPaginationExpenseGrid,
        }}
        pageSizeOptions={[5, 10]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
        }}
        disableVirtualization={true} // TODO: NG-1209 I think we may have an issue with row item ids colliding as you switch client / matter, this fix is okay for now
        disableColumnMenu={true}
        disableColumnSelector={true}
      />
    </div>
  );
}
