import React, { useState, useEffect } from "react";

//Mui imports
import { Grid } from "@mui/material";
import SummaryPanel from "../../../Components/SummaryPanel";
import TablePanel from "../../../Components/TablePanel";
import DataTable from "../../../Components/DataGrid";
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";
import { useLazyQuery } from "@apollo/client";
import GET_CLIENT_ACTIVITY_FEE from "../../../Graphql/queries/GET_CLIENT_ACTIVITY_FEE";
import jsUtils from "../../../utils/jsUtils";
import dayjs from "dayjs";
import GET_BILLS_DATA from "../../../Graphql/queries/GET_BILLS_DATA";

const INITIAL_SUMMARY = [
  {
    title: "Count :",
    value: 0,
  },
  {
    title: "Time Fees :",
    value: "$ 00.00",
  },
  {
    title: "Task Fees :",
    value: "$ 00.00",
  },
  {
    title: "Simple Fees :",
    value: "$ 00.00",
  },
  {
    title: "Total Fees :",
    value: "$ 00.00",
  },
  {
    title: "Variance :",
    value: "$ 00.00",
  },
  {
    title: "Billed :",
    value: "$ 00.00",
  },
  {
    title: "Expenses :",
    value: "$ 00.00",
  },
  {
    title: "Variance :",
    value: "$ 00.00",
  },
  {
    title: "Billed :",
    value: "$ 00.00",
  },
  {
    title: "Discounts :",
    value: "$ 00.00",
  },
  {
    title: "Surcharges :",
    value: "$ 00.00",
  },
  {
    title: "Interest :",
    value: "$ 00.00",
  },
  {
    title: "Taxes :",
    value: "$ 00.00",
  },
  {
    title: "Retainer :",
    value: "$ 00.00",
  },
  {
    title: "Total Billed :",
    value: "$ 00.00",
  },
  {
    title: "Total Paid :",
    value: "$ 00.00",
  },
  {
    title: "Total Adjusted :",
    value: "$ 00.00",
  },
  {
    title: "Total Due :",
    value: "$ 00.00",
  },
];

export default function BillsTab() {
  const { inquiryTarget } = useInquiryTarget();
  const [clientMatterState, setClientMatterState] = useState(null);
  const [billsList, setBillslist] = useState([]);
  const [billsSummary, setBillsSummary] = useState(INITIAL_SUMMARY);
  // eslint-disable-next-line no-unused-vars
  const [getBillsData] = useLazyQuery(
    GET_BILLS_DATA,
    {
      variables: clientMatterState,
      onCompleted: (data) => {
        const billsData = data?.getInquiryBills;
        setBillslist(billsData?.bills);

        if (billsData) {
          
          setBillsSummary([
            {
              title: "Count :",
              value: billsData?.count || 0,
            },
            {
              title: "Time Fees :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.timeFeesTotal),
            },
            {
              title: "Task Fees :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.taskFeesTotal),
            },
            {
              title: "Simple Fees :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.simpleFeesTotal),
            },
            {
              title: "Total Fees :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.allFeesTotal),
            },
            {
              title: "Variance :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.feesVariance),
            },
            {
              title: "Billed :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.feesBilled),
            },
            {
              title: "Expenses :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.expensesTotal),
            },
            {
              title: "Variance :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.expensesVariance),
            },
            {
              title: "Billed :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.expensesBilled),
            },
            {
              title: "Discounts :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.discountTotal),
            },
            {
              title: "Surcharges :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.surchargesTotal),
            },
            {
              title: "Interest :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.interestTotal),
            },
            {
              title: "Taxes :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.taxesTotal),
            },
            {
              title: "Retainer :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.retainerTotal),
            },
            {
              title: "Total Billed :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.billedTotal),
            },
            {
              title: "Total Paid :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.paidTotal),
            },
            {
              title: "Total Adjusted :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.adjestedTotal),
            },
            {
              title: "Total Due :",
              value: jsUtils.negativeCurrencyFormatter(billsData?.dueTotal),
            },
          ]);
        }
      },
    }
  );

  useEffect(() => {
    if (inquiryTarget) {
      let { client, matter, startDate, endDate } = inquiryTarget;

      //if at least client is selected, call the API
      if (client) {
        setClientMatterState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : 0, //if no matter is selected, pass O as a jobId
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
        });
      }
    }
  }, [inquiryTarget]);

  useEffect(() => {
    if (clientMatterState) {
      getBillsData();
    }
  }, [clientMatterState]);

  return (
    <Grid container spacing={2}>
      <Grid item sx={3} sm={3} md={3} lg={3} xl={2.3}>
        <SummaryPanel List={billsSummary} />
      </Grid>
      <Grid item sx={9} sm={9} md={9} lg={9} xl={9.7}>
        <TablePanel title="Bills">
          <DataTable rows={billsList} columns={columns} />
        </TablePanel>
      </Grid>
    </Grid>
  );
}

// Updating: "Worked", "Billed", "Paid", "Adjusted", "Due"
// TODO: NG-1209 Overriding the utils formatting to show "$0.00" instead of hyphen to match CAH
// Not sure if / where else this same formatting is desired
const customNegativeCurrencyFormatter = (value) => {
  const formattedValue = jsUtils.negativeCurrencyFormatter(value);
  return formattedValue === "-" ? "$ 0.00" : formattedValue;
}

const columns = [
  { field: "id", headerName: "ID", width: 100, isCalcutateIcon: false },
  { field: "status", headerName: "Status", width: 100 },
  {
    field: "billDate",
    headerName: "Bill Date",
    isCalcutateIcon: false,
    width: 120,
    renderCell: (params) => (
      <span className="column-header">{dayjs(params.row.billDate).format("MM-DD-YYYY")}</span>
    ),
  },
  {
    field: "clientCode",
    headerName: "Client",
    isCalcutateIcon: false,
    width: 130,
  },
  {
    field: "jobCode",
    headerName: "Matter",
    isCalcutateIcon: false,
    width: 130,
  },
  {
    field: "amount",
    headerName: "Worked",
    valueGetter: (params) =>
      customNegativeCurrencyFormatter(params.row.amount),
    isCalcutateIcon: true,
    width: 130,
  },
  {
    field: "amountBilled",
    headerName: "Billed",
    valueGetter: (params) =>
      customNegativeCurrencyFormatter(params.row.amountBilled),
    isCalcutateIcon: true,
    width: 150,
  },
  {
    field: "amountPaid",
    headerName: "Paid",
    valueGetter: (params) =>
      customNegativeCurrencyFormatter(params.row.amountPaid),
    isCalcutateIcon: true,
    width: 150,
  },

  {
    field: "amountAdjusted",
    headerName: "Adjusted",
    valueGetter: (params) =>
      customNegativeCurrencyFormatter(params.row.amountAdjusted),
    isCalcutateIcon: true,
    width: 150,
  },
  {
    field: "amountDue",
    headerName: "Due",
    valueGetter: (params) =>
      customNegativeCurrencyFormatter(params.row.amountDue),
    isCalcutateIcon: true,
    width: 150,
  },
  {
    field: "billInstruction",
    headerName: "Bill Instruction",
    width: 180,
  },
];
