import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import DataTable from "../../../Components/DataGrid";
import SummaryPanel from "../../../Components/SummaryPanel";
import TablePanel from "../../../Components/TablePanel";
import GET_WIP_LEDGER from "../../../Graphql/queries/GET_WIP_LEDGER";
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";
import jsUtils from "../../../utils/jsUtils";

const placeholderSummary = [
  {
    title: "Count :",
    value: 0,
  },
  {
    title: "Hours :",
    value: 0,
  },
  {
    title: "Fees :",
    value: "-",
  },
  {
    title: "Expenses :",
    value: "-",
  },
  {
    title: "Balance(ITD) :",
    value: "-",
  },
];

const columns = [
  { field: "id", headerName: "ID", width: 100, isCalcutateIcon: false },
  { field: "status", headerName: "Status", isCalcutateIcon: true, width: 100 },
  {
    field: "ledgerDate",
    headerName: "Date",
    isCalcutateIcon: false,
    width: 120,
    renderCell: (params) => (
      <span className="column-header">
        {dayjs(params.row.ledgerDate).format("MM-DD-YYYY")}
      </span>
    ),
  },
  { field: "type", headerName: "Type", isCalcutateIcon: false, width: 150 },
  {
    field: "billable",
    headerName: "Billable",
    isCalcutateIcon: false,
    width: 150,
  },
  {
    field: "clientCode",
    headerName: "Client",
    isCalcutateIcon: false,
    width: 130,
  },
  {
    field: "jobCode",
    headerName: "Matter",
    isCalcutateIcon: false,
    width: 130,
  },
  {
    field: "hours",
    headerName: "Hours",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">{params.row.hours.toFixed(2)}</span>
    ),
  },
  {
    field: "quantity",
    headerName: "Quantity",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">{params.row.quantity.toFixed(2)}</span>
    ),
  },
  {
    field: "rate",
    headerName: "Rate",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.negativeCurrencyFormatter(params.row.rate)}
      </span>
    ),
  },
  {
    field: "amount",
    headerName: "Amount",
    isCalcutateIcon: true,
    width: 150,
    renderCell: (params) => (
      <span className="column-header">
        {jsUtils.negativeCurrencyFormatter(params.row.amount)}
      </span>
    ),
  },
  {
    field: "balance",
    headerName: "Balance",
    isCalcutateIcon: true,
    width: 180,
    renderCell: (params) => (
      <span className="column-header"> {jsUtils.negativeCurrencyFormatter(params.row.balance)}</span>
    ),
  },
  {
    field: "description",
    headerName: "Remarks",
    isCalcutateIcon: false,
    width: 220,
  },
];

export default function WIPLedgerTab() {
  const { inquiryTarget } = useInquiryTarget();

  const { data } = useQuery(
    GET_WIP_LEDGER,
    {
      variables: {
        clientId: parseInt(inquiryTarget?.client.clientId),
        jobId: parseInt(inquiryTarget?.matter?.matterId || 0),
        startDate: dayjs(inquiryTarget?.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(inquiryTarget?.endDate).format("YYYY-MM-DD"),
      },
    }
  );

  const getWIPLedger = data?.getWIPLedger;
  const wipLedgers = getWIPLedger?.wipLedgers || [];
  
  const summary = getWIPLedger ? [
    {
      title: "Count :",
      value: getWIPLedger.count,
    },
    {
      title: "Hours :",
      value: getWIPLedger.hour.toFixed(2),
    },
    {
      title: "Fees :",
      value: jsUtils.negativeCurrencyFormatter(getWIPLedger.fees),
    },
    {
      title: "Expenses :",
      value: jsUtils.negativeCurrencyFormatter(getWIPLedger.expenses),
    },
    {
      title: "Balance(ITD) :",
      value: jsUtils.negativeCurrencyFormatter(getWIPLedger.balanceITD),
    },
  ] : placeholderSummary;

  return (
    <Grid container spacing={2}>
      <Grid item sx={3} sm={3} md={3} lg={3} xl={2.3}>
        <SummaryPanel List={summary} />
      </Grid>
      <Grid item sx={9} sm={9} md={9} lg={9} xl={9.7}>
        <TablePanel title="WIP Ledger">
          <DataTable rows={wipLedgers} columns={columns} />
        </TablePanel>
      </Grid>
    </Grid>
  );
}
