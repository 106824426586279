import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";

import { activityTabState } from "./utils/initialStates";
import jsUtils from "../../../../utils/jsUtils";

const ActivityTab = ({ data }) => {
  const [rows, setRows] = useState(activityTabState);

  useEffect(() => {
    if (data) {
      // Set rows
      setRows((prevRows) =>
        prevRows.map((row) => {
          switch (row.title) {
            case "Worked :":
              return {
                ...row,
                fee_amount: data?.feeWorked?.toFixed(2) || row.fee_amount,
                expense_amount: data?.expenseWorked?.toFixed(2) || row.expense_amount,
              };
            case "Worked (Billed) :":
              return {
                ...row,
                fee_amount: data?.feeWorkedBilled?.toFixed(2) || row.fee_amount,
                expense_amount: data?.expenseWorkedBilled?.toFixed(2) || row.expense_amount,
              };
            case "Mark Up / Down :":
              return {
                ...row,
                fee_amount: data?.feeVariance?.toFixed(2) || row.fee_amount,
                expense_amount: data?.expenseVariance?.toFixed(2) || row.expense_amount,
              };
            case "Billed :":
              return {
                ...row,
                fee_amount: data?.feeBilled?.toFixed(2) || row.fee_amount,
                expense_amount: data?.expenseBilled?.toFixed(2) || row.expense_amount,
                fee_rel: data.feeBilledRealization || "-",
                expense_rel: data.expenseBilledRealization || "-",
              };
            case "Discount :":
              return {
                ...row,
                fee_amount: data?.feeDiscount?.toFixed(2) || row.fee_amount,
                expense_amount: data?.expenseDiscount?.toFixed(2) || row.expense_amount,
              };
            case "Net Billed :":
              return {
                ...row,
                fee_amount: data?.feeNetDiscount?.toFixed(2) || row.fee_amount,
                fee_rel: data?.feeNetDiscountRealization || row.fee_rel,
                expense_amount: data?.expenseNetDiscount?.toFixed(2) || row.expense_amount,
                expense_rel: data?.expenseNetDiscountRealization || row.expense_rel,
              };
            case "Collected :":
              return {
                ...row,
                fee_amount: data?.feeCollected?.toFixed(2) || row.fee_amount,
                fee_rel: data?.feeCollectedRealization || row.fee_rel,
                expense_amount: data?.expenseCollected?.toFixed(2) || row.expense_amount,
                expense_rel: data?.expenseCollectedRealization || row.expense_rel,
              };
            case "Adjusted :":
              return {
                ...row,
                fee_amount: data?.feeAdjusted?.toFixed(2) || row.fee_amount,
                expense_amount: data?.expenseAdjusted?.toFixed(2) || row.expense_amount,
              };
            default:
              return row;
          }
        })
      );
    }
  }, [data]);

  return (
    <Box className="summary-table-content">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Activity</TableCell>
              <TableCell align="center">Fees Amount</TableCell>
              <TableCell align="center">Fees Realization(%)</TableCell>
              <TableCell align="center">Expenses Amount </TableCell>
              <TableCell align="center">Expenses Realization(%) </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ whiteSpace: "nowrap" }}>
            {rows.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" align="left">
                    {item.title}
                  </TableCell>
                  <TableCell align="center">{jsUtils.negativeCurrencyFormatter(item.fee_amount)}</TableCell>
                  <TableCell align="center">{jsUtils.formatRealizationPercentage(item.fee_rel)}</TableCell>
                  <TableCell align="center">{jsUtils.negativeCurrencyFormatter(item.expense_amount)}</TableCell>
                  <TableCell align="center">{jsUtils.formatRealizationPercentage(item.expense_rel)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ActivityTab;
